<template>
  <div>
    <el-menu-item index="home" @click="$router.push({ name: 'WomenBabyhome' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">首页</span>
    </el-menu-item>

    <el-submenu index="information" v-if="this.$store.state.women_baby.identity=='1'">
      <template slot="title">
        <i name="info" class="el-icon-s-custom"></i>
        <span style="font-size: 18px">个人数据</span>
      </template>
      <el-menu-item index="myh_history'" @click="$router.push({ name: 'myh_history_women' })">
        <i name="tubiao" class="el-icon-document-copy"></i>
        <span slot="title" style="font-size: 18px">检测项目/记录</span>
      </el-menu-item>
      <!-- <el-menu-item index="myh_tend" @click="$router.push({ name: 'myh_tend_women' })">
        <i name="tubiao" class="el-icon-data-analysis"></i>
        <span slot="title" style="font-size: 18px">总体趋势分析</span>
      </el-menu-item> -->
      <el-menu-item index="myh_tend" @click="$router.push({ name: 'Tendency_overall_women' })">
        <i name="tubiao" class="el-icon-data-analysis"></i>
        <span slot="title" style="font-size: 18px">综合趋势分析</span>
      </el-menu-item>
      <el-menu-item index="ExamReport" @click="$router.push({ name: 'ExamReport_women' })">
        <i name="tubiao" class="el-icon-document"></i>
        <span slot="title" style="font-size: 18px">综合体检报告</span>
      </el-menu-item>
    </el-submenu>

    <!-- <el-submenu index="remote" v-if="this.$store.state.women_baby.identity=='1'">
      <template slot="title">
        <i name="info" class="el-icon-first-aid-kit"></i>
        <span style="font-size: 18px">远程诊疗</span>
      </template>

      <el-menu-item index="Appointment" @click="$router.push({ name: 'Appointment_women' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">远程诊疗预约</span>
      </el-menu-item>

      <el-menu-item index="RemoteHistory" @click="$router.push({ name: 'RemoteHistory_women' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">预约、问诊历史</span>
      </el-menu-item>

      <el-menu-item index="reportUpload" @click="$router.push({ name: 'reportUpload_women' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">上传外部报告</span>
      </el-menu-item>

      <el-menu-item  index="RemoteHistory" @click="$router.push({ name: 'emergency3_women' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">急诊</span>
      </el-menu-item>
    </el-submenu> -->

    <el-menu-item v-if="this.$store.state.women_baby.identity=='1'" index="PsInfo" @click="$router.push({ name: 'PsInfo_women' })">
      <i name="info" class="el-icon-notebook-2"></i>
      <span slot="title" style="font-size: 18px">个人信息表</span>
    </el-menu-item>

    <!-- <el-menu-item v-if="this.$store.state.women_baby.identity=='1'" index="RelativeManage" @click="$router.push({ name: 'RelativeManage_women' })">
      <i name="info" class="el-icon-user-solid"></i>
      <span slot="title" style="font-size: 18px">亲情关怀</span>
    </el-menu-item> -->

    <el-menu-item v-if="this.$store.state.women_baby.identity=='1'" index="RelativeManage" @click="$router.push({ name: 'pregnant_tips' })">
      <i name="info" class="el-icon-user-solid"></i>
      <span slot="title" style="font-size: 18px">孕期知识</span>
    </el-menu-item>

    <el-menu-item v-if="this.$store.state.women_baby.identity=='1'" index="AccountSetting" @click="$router.push({ name: 'AccountSetting_women' })">
      <i name="info" class="el-icon-user"></i>
      <span slot="title" style="font-size: 18px">账户设置</span>
    </el-menu-item>

    <el-menu-item v-if="this.$store.state.women_baby.identity=='2'" index="AccountSetting" @click="$router.push({ name: 'memberData_women' })" >
      <i name="info" class="el-icon-user"></i>
      <span slot="title" style="font-size: 18px">成员管理</span>
    </el-menu-item>

    <el-menu-item index="home" @click="toBigScreen()">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">一屏管理</span>
    </el-menu-item>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "www.xin-gou.com/womenbaby_big_screen/",
      dialogVisible: false
    };
  },
  components: {
    // SubMenu
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done(
            this.dialogVisible = false
          )
          // window.location.href = "http://" + this.url;
        })
        .catch(() => { });
    },

    // 跳转到大屏
    toBigScreen() {
      this.dialogVisible = false
      window.location.href = "https://" + this.url;
    }
  },
};
</script>

<style scoped>
/* .site-sidebar__menu template span {
  font-size: 16px;
} */
</style>
